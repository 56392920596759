<template>
  <section class="is-primary is-fullheight login-page">
    <div class="login-background">
      <div class="columns is-centered">
        <div class="column"></div>
        <div class="column is-one-third">
          <div class="card card-login-form">
            <div class="card-box">
              <header class="card-header">
                <div class="columns row-title">
                  <div class="column column-logo is-full is-size-1">
                    <img width="154px" class="logo" src="../../assets/svgs/Logo/logo_color.svg" alt="">
                  </div>
                </div>
              </header>
            </div>
            <div class="card-content">
              <div class="content" @keyup.enter="forgotPassword()">
                <div class="content-title">
                  <span>{{$t('login.content_title')}}</span>
                </div>
                <div class="field has-text-centered mt-5">
                  <div class="field m__bottom--30">
                    <div class="control has-icons-left">
                      <input type="text" v-model="employee_id" id="login-email"
                             :placeholder="$t('login.input_field.employee_id.placeholder')"
                             @keyup.enter="forgotPassword()"
                             :class="{'is-error' :( !$v.employee_id.required && $v.$dirty) || (error_server.employee_id && error_server.employee_id.length)}"
                             class="input is-large">
                      <span class="icon is-medium is-left">
                        <img class="icon-input-user" src="../../assets/svgs/Icon/account.svg" alt="">
                      </span>
                    </div>
                    <span class="error pt-1"
                          v-if="!$v.employee_id.required && $v.$dirty"> {{ $t('forgot_password.validate.employee_id_required') }}</span>
                  </div>
                  <div class="field has-text-centered m__top--30">
                    <button @click="forgotPassword()" class="button  btn-login is-align-items-flex-end">
                      {{ $t('forgot_password.button') }}
                    </button>
                  </div>
                  <div class="field has-text-centered m__top--17">
                    <router-link :to="{name: 'login'}" class="forgot-password-link">
                      {{ $t('buttons.return') }}
                    </router-link>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </section>
</template>


<script>
import {maxLength, required} from "vuelidate/lib/validators";
import {AuthService} from '@/services';

export default {
  name: "ForgotPassword",
  data() {
    return {
      employee_id: "",
      error_server: {},
      submitAvailable: true
    };
  },
  validations: {
    employee_id: {
      required,
      maxLength: maxLength(255)
    },
  },
  methods: {
    forgotPassword() {
      this.error_server = {}
      this.$v.$touch()
      if (!this.$v.$invalid && this.submitAvailable) {
        this.submitAvailable = false
        AuthService.forgotPassword(this.employee_id).then(data => {
          this.submitAvailable = true
          if (data.status == 200) {
            this.$toast.success(this.$t('forgot_password.message_success'))
            this.$router.push({name: 'login'}, () => {
            })
          }
        }).catch((err) => {
          this.submitAvailable = true
          this.error_server = err.data.errors
          this.$toast.error(err.data.message)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/login.scss";

.card-content {
  padding-top: 0;
  padding-bottom: 0;
}

</style>
